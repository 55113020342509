import Logo from './Logo';
import {
  Bars3Icon,
  HomeIcon,
  MapPinIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectItemsInCart } from '../features/cart/cartSlice';
import {
  closeLocationModal,
  openLocationModal,
  setLang,
  toggleMenu,
} from '../features/app/appSlice';
import LocationModal from './LocationModal';
import Lang from './lang.jsx';
import { useEffect, useState } from 'react';
import { selectAddress } from '../features/address/addressSlice';
import { selectdLang } from '../features/app/appSlice';
import axios from 'axios';

const Header = () => {
  const { isMenuOpen, isLocationModalOpen } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const items = useSelector(selectItemsInCart);
  const { address } = useSelector(selectAddress);
  const [selectedLang, setLanguage] = useState('EN');
  const [settings, setSettings] = useState({});

  const handleToggleMenu = () => dispatch(toggleMenu());
  const lang = useSelector(selectdLang);

  const handleLang = () => {
    const newLang = selectedLang === 'EN' ? 'FN' : 'EN';
    setLanguage(newLang);
    dispatch(setLang(newLang));
  };

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        'https://back-end.hot-sub.ca/api/settings/1'
      );
      setSettings(response.data.data);
    } catch (error) {
      console.error('There was an error fetching the settings!', error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  const handleCall = () => {
    const formattedNumber = encodeURIComponent(settings.phone_number);
    window.location.href = `tel:${formattedNumber}`;
  };

  return (
    <header className='sticky w-full top-0 bg-white z-20 py-4 border-b shadow-sm border-gray-100'>
      <div className='container-max flex justify-between items-center'>
        <div className='flex items-center gap-2 md:gap-4'>
          <Logo />
          <button onClick={handleLang} className='arrow-button'>
            <span
              className={
                selectedLang === 'FN' ? 'fn-gradient-text' : 'en-gradient-text'
              }>
              {selectedLang === 'FN' ? 'FR' : 'EN'}
            </span>
            <span>▼</span>
          </button>
        </div>

        {isLocationModalOpen ? <LocationModal /> : null}

        <ul className='text-zinc-700 ml-auto gap-2 md:gap-4 items-center hidden md:flex'>
          <li>
            <Link
              to='/'
              className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
              <HomeIcon className='w-4 h-4 text-gray-700' />
              <p className='hidden md:block'>Home</p>
            </Link>
          </li>
          <li>
            <a
              href={settings.location_url}
              target='_blank'
              rel='noopener noreferrer'
              className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
              <MapPinIcon className='w-4 h-4 text-gray-700' />
              <p className='hidden md:block'>Location</p>
            </a>
          </li>
          <li>
            <Link
              onClick={handleCall}
              className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
              <PhoneIcon className='w-4 h-4 text-gray-700' />
              <p>Contact</p>
            </Link>
          </li>
        </ul>

        {!isMenuOpen ? (
          <div className='shadow-lg transition-all fixed top-full -right-[100%] bg-white h-screen p-4 px-8'>
            <>
              <ul className='text-zinc-700 space-y-4'>
                <li>
                  <Link
                    to='/'
                    className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
                    <HomeIcon className='w-4 h-4 text-gray-700' />
                    <p>Home</p>
                  </Link>
                </li>
                <li>
                  <a
                    href={settings.location_url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
                    <MapPinIcon className='w-4 h-4 text-gray-700' />
                    <p>Location</p>
                  </a>
                </li>
                <li>
                  <Link
                    onClick={handleCall}
                    className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
                    <PhoneIcon className='w-4 h-4 text-gray-700' />
                    <p>Contact</p>
                  </Link>
                </li>
              </ul>
            </>
          </div>
        ) : (
          <div className='shadow-lg transition-all md:hidden absolute top-full right-0 bg-white h-screen p-4 px-8'>
            <>
              <ul className='text-zinc-700 space-y-4'>
                <li>
                  <Link
                    to='/'
                    className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
                    <HomeIcon className='w-4 h-4 text-gray-700' />
                    <p>Home</p>
                  </Link>
                </li>
                <li>
                  <a
                    href={settings.location_url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
                    <MapPinIcon className='w-4 h-4 text-gray-700' />
                    <p>Location</p>
                  </a>
                </li>
                <li>
                  <Link
                    onClick={handleCall}
                    className='p-2 md:px-4 hover:bg-gray-50 rounded-md flex items-center gap-2'>
                    <PhoneIcon className='w-4 h-4 text-gray-700' />
                    <p>Contact</p>
                  </Link>
                </li>
              </ul>
            </>
          </div>
        )}

        <button className='block md:hidden' onClick={handleToggleMenu}>
          <Bars3Icon className='w-6 h-6' />
        </button>
      </div>
    </header>
  );
};
export default Header;
