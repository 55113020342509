import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import RestaurantCard, { withTopRatedLabel } from './RestaurantCard';
import ShimmerCard from './ShimmerCard';
import { useSelector } from 'react-redux';
import { selectdLang } from '../features/app/appSlice';

const RestaurantList = () => {
  const RestaurantCardTopRated = withTopRatedLabel(RestaurantCard);
  const lang = useSelector(selectdLang);
  const [selectedLang, setSelectedLang] = useState('EN');
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setSelectedLang(lang);
  }, [lang]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get('https://back-end.hot-sub.ca/api/categories/all')
      .then((response) => {
        const sortedCategories = response.data.data.sort(
          (a, b) => a.index - b.index
        );
        setCategories(sortedCategories);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching the categories!', error);
        setIsLoading(false);
      });
  }, [selectedLang]);

  return (
    <div className='container-max'>
      {categories.map((category) => (
        <div key={category.id}>
          <h2 className='my-4 mt-8 font-bold text-2xl text-zinc-700'>
            {selectedLang === 'EN' ? category.en_name : category.fr_name}
          </h2>
          <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8'>
            {isLoading
              ? Array.from({ length: 4 }).map((_, i) => <ShimmerCard key={i} />)
              : category.items.map((restaurant, i) => (
                  <Link
                    className='hover:scale-95 transition ease-in-out duration-300 relative z-10 item-background'
                    key={i}>
                    {restaurant.rate >= 4.2 ? (
                      <RestaurantCardTopRated restaurant={restaurant} />
                    ) : (
                      <RestaurantCard restaurant={restaurant} />
                    )}
                  </Link>
                ))}
          </div>
        </div>
      ))}
      {categories.length === 0 && !isLoading && <p>No restaurant found!</p>}
    </div>
  );
};

export default RestaurantList;
