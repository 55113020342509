import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/food.css';

const Banner = ({ descImage, mobImage }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const updateImages = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateImages);
    return () => {
      window.removeEventListener('resize', updateImages);
    };
  }, []);

  return (
    <div className='keen-slider__slide'>
      <picture>
        <source media='(max-width: 720px)' srcSet={mobImage} />
        <source media='(min-width: 721px)' srcSet={descImage} />
        <img
          loading='lazy'
          className='block w-full images'
          src={screenWidth <= 720 && screenWidth >= 360 ? mobImage : descImage}
          alt='Offer Banner'
        />
      </picture>
    </div>
  );
};

export default Banner;
