import CustomImage from './CustomImage';
import '../assets/_hero-section.scss';
import { useEffect, useState } from 'react';
import { selectdLang } from '../features/app/appSlice';
import { useSelector } from 'react-redux';
import axios from 'axios';

import slider11 from '../assets/slider/footer.jpg';
import insta from '../assets/contact/insta.png';
import call from '../assets/contact/call.png';
import location from '../assets/contact/location.png';

const HeroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [slider11];
  const lang = useSelector(selectdLang);
  const [selectedLang, setLanguage] = useState('EN');
  const [settings, setSettings] = useState({});

  const fetchSettings = async () => {
    try {
      const response = await axios.get(
        'https://back-end.hot-sub.ca/api/settings/1'
      );
      setSettings(response.data.data);
    } catch (error) {
      console.error('There was an error fetching the settings!', error);
    }
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  const handleCall = () => {
    const formattedNumber = encodeURIComponent(settings.phone_number);
    window.location.href = `tel:${formattedNumber}`;
  };

  useEffect(() => {
    setLanguage(lang);
  }, [lang]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className='section hero container-max flex flex-col md:flex-row gap-4'>
      <div className='typography flex-1'>
        <h1 className='title'>
          {selectedLang === 'EN' ? 'About Hotsub' : 'À propos de Hotsub'}
        </h1>
        <p className='info'>
          {selectedLang === 'EN'
            ? 'Discover Hot Sub! We are passionate about fresh, tasty subs served just the way you like. Join us for a sub experience that is both delicious and satisfying !'
            : 'Découvrez Hot Sub ! Nous sommes passionnés par les subs frais et savoureux, servis exactement comme vous le souhaitez. Rejoignez-nous pour une expérience de sub délicieuse et satisfaisante !'}
        </p>
        <div className='w-full md:w-3/5 lg:w-3/5 flex items-center justify-between'>
          <img
            loading='lazy'
            onClick={handleCall}
            width='60'
            height='60'
            src={call}
            className='cursor-pointer'
            alt='call'
          />{' '}
          <a
            href={settings.location_url}
            target='_blank'
            rel='noopener noreferrer'>
            <img
              loading='lazy'
              width='60'
              height='60'
              src={location}
              alt='location'
            />
          </a>{' '}
          <a
            href={settings.instagram_url}
            target='_blank'
            rel='noopener noreferrer'>
            <img
              loading='lazy'
              width='60'
              height='60'
              src={insta}
              alt='insta'
            />
          </a>{' '}
        </div>
      </div>
      <div className='gallery flex-1'>
        <CustomImage
          key={currentImageIndex}
          imgSrc={images[currentImageIndex]}
          pt={'90%'}
        />
      </div>
    </div>
  );
};
export default HeroSection;
